<template>
  <section id="contents">
    <b-row>
      <b-col>
        <b-alert
          v-model="showAlert"
          variant="danger"
          dismissible
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="FilmIcon"
            />
            <span class="ml-25">{{ $t("contents.contentAlert") }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-form-checkbox-group
      v-model="selectContent"
      name="some-radios"
    >
      <b-overlay
        variant="transparent"
        :show="overlay"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row v-if="!manual">
          <b-col
            v-for="folder in contents"
            :id="folder.node.id +
              ' ' +
              (folder.node.contentContentOrder.edges.length == 0
                ? folder.node.order
                : folder.node.contentContentOrder.edges[0].node.order)
            "
            :key="folder.node.id"
            class="mt-2"
            md="6"
            lg="3"
            xl="3"
            sm="12"
          >
            <b-card
              class="cardContent"
              no-body
            >
              <b-img
                :src="buildImageUrl(folder)"
                class="card-img-top cursor-pointer"
                img-alt="Card image"
                fluid-grow
                @click="
                  choice == false
                    ? routeToEditView(folder.node.id)
                    : clickCheck(folder.node)
                "
                @error="errorImg"
              />
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    class="mb-25"
                  >
                    <span
                      v-if="folder.node.state == 'GRE'"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />

                    <span
                      v-if="folder.node.state == 'YEW'"
                      class="mr-50 bullet bullet-warning bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'RED'"
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'BCK'"
                      class="mr-50 bullet bullet-dark bullet-sm"
                    />
                    {{ folder.node.name }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    {{ $t(typeOptions[folder.node.type]) }}
                  </b-card-text>
                </div>
              </b-card-header>
              <b-card-body>
                <div
                  v-if="choice != true"
                  class="actions"
                >
                  <div class="ml-1">
                    <feather-icon
                      v-if="folder.node.state == 'GRE'"
                      v-b-tooltip.hover.top.v-primary="released(folder.node)
                        ? $t('contVis')
                        : $t('contOc')
                      "
                      class="text-white"
                      :icon="released(folder.node) ? 'EyeIcon' : 'EyeOffIcon'"
                    />
                  </div>
                  <div class="action">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          color="white"
                          icon="MoreVerticalIcon"
                          size="20"
                        />
                      </template>
                      <b-dropdown-item @click="dropContentByCategory(folder)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t("contents.quitContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="showModal(folder.node, false)">
                        <feather-icon
                          icon="CornerRightUpIcon"
                          size="18"
                        />
                        {{ $t("contents.moveContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="showModal(folder.node, true)">
                        <feather-icon
                          icon="FolderPlusIcon"
                          size="18"
                        />
                        {{ $t('addCategory') }}
                      </b-dropdown-item>

                      <b-dropdown-item @click="routeToEditView(folder.node.id)">
                        <feather-icon
                          icon="Edit2Icon"
                          size="18"
                        />
                        {{ $t("contents.editContent") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteContent(folder.node.id)">
                        <feather-icon
                          icon="DeleteIcon"
                          size="18"
                        />
                        {{ $t("contents.dropContent") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div
                  v-else
                  class="actions right"
                >
                  <div
                    class="action"
                    @click="clickCheck(folder.node)"
                  >
                    <b-form-checkbox
                      class="ml-2"
                      disabled
                      :value="folder.node.id"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <draggable
          v-else
          v-model="contents"
          class="row manualOrder rounded"
        >
          <b-col
            v-for="folder in contents"
            :id="folder.node.id +
              ' ' +
              (folder.node.contentContentOrder.edges.length == 0
                ? folder.node.order
                : folder.node.contentContentOrder.edges[0].node.order +
                  ' ' +
                  folder.node.contentContentOrder.edges[0].node.id)
            "
            :key="folder.node.id"
            class="mt-2 hang-on-hover"
            md="6"
            lg="3"
            xl="3"
            sm="12"
            @dragend="updateOrder($event)"
          >
            <b-card
              class="cardContent cursor-move"
              no-body
            >
              <b-img
                :src="buildImageUrl(folder)"
                class="card-img-top"
                img-alt="Card image"
                fluid-grow
                @error="errorImg"
              />
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    class="mb-25"
                  >
                    <span
                      v-if="folder.node.state == 'GRE'"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'YEW'"
                      class="mr-50 bullet bullet-warning bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'RED'"
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    <span
                      v-if="folder.node.state == 'BCK'"
                      class="mr-50 bullet bullet-dark bullet-sm"
                    />
                    {{ folder.node.name }}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    {{ $t(typeOptions[folder.node.type]) }}
                  </b-card-text>
                </div>
              </b-card-header>
            </b-card>
          </b-col>
        </draggable>
      </b-overlay>
    </b-form-checkbox-group>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :exclude-ids="code"
          :type="true"
          :especial="true"
          :columns="categorySelectorTable"
          @confirm-selection="confirm"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BImg,
  BAlert,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  VBTooltip,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, utf8ToB64, showToast, checkRelationOrder, cleanRelationContents, removeRelation, removeResources,
} from '@/store/functions'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import configContentType from '@core/utils/contentType'
import CategorySelector from '../../common/CategorySelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BAlert,
    BImg,
    draggable,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BCard,
    BCardBody,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BCol,
    BRow,
    BButton,
    CategorySelector,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    manual: {
      type: Boolean,
      default: false,
    },
    code: {
      default: '',
    },
    actions: {
      type: String,
      default: null,
    },
    contenidos: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
    choice: {
      type: Boolean,
      default: false,
    },
    todos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contents: [],
      typeOptions: configContentType(true, false),
      overlay: false,
      clickContent: null,
      cardmoveId: [],
      cardmoveOrder: [],
      selectContent: [],
      infoContent: [],
      orderOrig: null,
      headers: {},
      categoryName: null,
      categoryId: null,
      idOrig: null,
      currentParentFolder: null,
      perPage: 20,
      currentPage: 1,
      rows: null,
      catContentId: [],
      userData: getUserData(),
      allFinalCategories: null,
      showSelector: false,
      addCat: false,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'type', label: this.$t('type') },
      ],
      showAlert: false,
    }
  },
  watch: {
    choice() {
      this.selectContent = []
      this.infoContent = []
      this.$emit('choices', this.selectContent)
    },
    todos() {
      if (this.todos) {
        this.selectContent = []
        this.infoContent = []
        this.contents.forEach(element => {
          this.clickCheck(element.node, true)
        })
      } else {
        this.selectContent = []
        this.infoContent = []
      }
      this.$emit('choices', this.selectContent)
    },
    actions() {
      switch (this.actions) {
        case 'ADD':
          this.showModal(null, true)
          break
        case 'MOV':
          this.showModal(null, false)
          break
        case 'REM':
          this.dropContentByCategory()
          break
        case 'DEL':
          this.deleteContent()
          break

        default:
          break
      }
      this.$emit('actReturn', null)
    },
    contenidos() {
      this.contents = this.contenidos.edges
    },
  },
  mounted() {
    this.contents = this.contenidos.edges
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (!this.todos) this.selectContent = []
    try {
      const getOrder = node => (node.contentContentOrder.totalCount === 0 ? node.order : node.contentContentOrder.edges[0].node.order)

      this.contents.sort((a, b) => getOrder(a.node) - getOrder(b.node))
      /*  this.contents.sort(
        (a, b) => (a.node.contentContentOrder.totalCount == 0
          ? a.node.order
          : a.node.contentContentOrder.edges[0].node.order)
          - (b.node.contentContentOrder.totalCount == 0
            ? b.node.order
            : b.node.contentContentOrder.edges[0].node.order),
      ) */
    } catch (error) {
      console.log(error)
    }
  },

  methods: {
    clickCheck(node, type = null) {
      if (type == null) {
        const i = this.selectContent.indexOf(node.id)
        const a = this.infoContent.map(e => e.id).indexOf(node.id)

        if (i !== -1) {
          this.selectContent.splice(i, 1)
          this.infoContent.splice(a, 1)
        } else {
          this.selectContent.push(node.id)
          this.infoContent.push(node)
        }
        this.$emit('choices', this.selectContent)
      } else {
        const i = this.selectContent.indexOf(node.id)
        const a = this.infoContent.map(e => e.id).indexOf(node.id)

        if (i !== -1) {
          this.selectContent.splice(i, 1)
          this.infoContent.splice(a, 1)
        } else {
          this.selectContent.push(node.id)
          this.infoContent.push(node)
        }
      }
    },
    status() {
      let isActive = false
      switch (this.state) {
        case 'GRE':
          isActive = true
          break
        case 'YEW':
          isActive = true

          break
        case 'RED':
          isActive = false
          break
        case 'BCK':
          isActive = false
          break

        default:
          break
      }

      let query = 'mutation{'
      this.selectContent.forEach((element, i) => {
        query += `
          m${i + 1}:updateContents(id:"${element}",input:{state:${this.state
},isActive:${isActive}}){
            content {
              id
              isActive
            }
          }

        `
      })
      query += '}'

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          this.getContents()
        })
      let estado = ''
      switch (this.state) {
        case 'GRE':
          estado = this.$t('ecommerce.public')
          break
        case 'YEW':
          estado = this.$t('dataGeneric.internal')

          break
        case 'RED':
          estado = this.$t('ecommerce.NoPublic')

          break

        default:
          break
      }
      if (this.selectContent.length >= 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              this.selectContent.length === 1
                ? `${this.$t('statusContentCh')} ${estado}`
                : `${this.$t('statusContentsCh')} ${estado}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }
    },
    moveToCategory(category) {
      this.hideModal()
      this.categoryId = category.node.id
      let query = `query
      {`
      for (let i = 0; i < this.selectContent.length; i += 1) {
        query += `
        q${i + 1}: allContents(id:"${this.selectContent[i]}"){
            edges {
              node {
                id
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          `
      }
      query += `
      }`

      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)

          const deleteFilter = []
          let query2 = ` mutation
      {`
          for (let a = 0; a < this.selectContent.length; a += 1) {
            const data = res.data.data[`q${a + 1}`].edges[0].node
            deleteFilter.push(data.id)

            const categoriasRes = []
            try {
              const categories = data.categories.edges.filter(
                element => element.node.id !== this.code,
              )

              categories.forEach(item => categoriasRes.push(item.node.id))

              categoriasRes.push(this.categoryId)
            } catch (error) {
              console.log(error)

              categoriasRes.push(this.categoryId)
            }

            query2 += `
              m${a + 1}: updateContents(id:"${data.id}", input:{
              categories: [${categoriasRes}]
            }) {
              content{
                id
                name
                client {
                  id
                  name
                }
                categories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }

                `
          }
          query2 += `
            }`
          axios
            .post('', { query: query2 })
            .then(result => {
              messageError(result, this)

              deleteFilter.forEach(element => {
                this.contents = this.contents.filter(
                  item => item.node.id !== element,
                )
              })

              removeRelation(this.code, this.selectContent) // eliminamos relación anterior
              checkRelationOrder(this.categoryId) // Creamos nueva relación
              if (this.contents.length === 0) {
                this.$emit('vacio', true)
              } else {
                this.$emit('reload', this.code)
              }
              this.selectContent = []
              this.infoContent = []
              this.$emit('choices', this.selectContent)
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(res => {
          console.log(res)
        })
    },
    dropContentByCategory(content = null) {
      if (content != null) this.selectContent.push(content.node.id)
      this.$swal({
        title: this.$t('removeFromCat'),
        text:
          this.$t('sureOrphan'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let query = ` query
      {`

            for (let i = 0; i < this.selectContent.length; i += 1) {
              query += `
        q${i + 1}: allContents(id:"${this.selectContent[i]}"){
            edges {
              node {
                id
                contentContentOrder
                            ${this.catSelc != null
    ? `(category:"${utf8ToB64(
      `category:${this.code}`,
    )}")`
    : ''
}{
                                totalCount
                                edges {
                                  node {
                                    id
                                    order
                                    category {
                                      id
                                      name
                                    }
                                  }
                                }
                              }
                categories {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          `
            }
            query += `
      }`

            axios
              .post('', { query })
              .then(res => {
                messageError(res, this)

                let data = []
                const filterDelete = []
                let query2 = ` mutation
                {`
                for (let a = 0; a < this.selectContent.length; a += 1) {
                  data = res.data.data[`q${a + 1}`].edges[0].node
                  filterDelete.push(data.id)
                  const categoriasRes = []

                  try {
                    const categories = data.categories.edges.filter(
                      element => element.node.id !== this.code,
                    )
                    categories.forEach(item => categoriasRes.push(item.node.id))
                    // eslint-disable-next-line no-empty
                  } catch (error) { }

                  query2 += `
                    m${a + 1}: updateContents(id:"${data.id}", input:{
                    categories: [${categoriasRes}]
                  }) {
                    content{
                      id
                      name
                      client {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }

                      `
                }
                query2 += '}'
                axios
                  .post('', { query: query2 })
                  .then(r => {
                    messageError(r, this)

                    filterDelete.forEach(element => {
                      this.contents = this.contents.filter(
                        item => item.node.id !== element,
                      )
                    })

                    if (this.contents.length === 0) {
                      this.$emit('vacio', true)
                    } else {
                      this.$emit('reload', this.code)
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })

                query = ` mutation
                {`
                this.selectContent.forEach((element, a) => {
                  data = res.data.data[`q${a + 1}`].edges[0].node

                  if (data.contentContentOrder.totalCount !== 0) {
                    data.contentContentOrder.edges.forEach(e => {
                      if (e.node.category.id === this.code) {
                        query += `
                    m${a + 1}: deleteContentOrder(id:"${e.node.id}") {
                      found
                      deletedId
                    }
                    `
                      }
                    })
                  }
                })
                query += '}'
                axios
                  .post('', { query })
                  .then(r => {
                    messageError(r, this)

                    this.selectContent = []
                    this.infoContent = []
                    this.$emit('choices', this.selectContent)
                  })
                  .catch(err => {
                    console.log(err)
                  })
              })
              .catch(() => {
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    routeToEditView(folderId) {
      this.$router.push({ name: 'podcast-edit', params: { id: folderId } })
    },
    addToCategory(cat) {
      this.categoryId = cat.node.id
      this.categoryName = cat.node.name
      this.hideModal()

      const data = []
      this.selectContent.forEach(element => {
        data.push(element)
      })

      cat.node.categoryContent.edges.forEach(element => {
        data.push(element.node.id)
      })

      const dataArr = new Set(data)

      const result = [...dataArr]

      axios
        .post('', {
          query: `
         mutation{
          updateCategory(id:"${this.categoryId}",input:{categoryContent:[${result}]}){
            category{
              id
              name
              categoryContent(orderBy:"order,-created_at"){
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
        `,
        })
        .then(res => {
          messageError(res, this)

          checkRelationOrder(this.categoryId).then(() => {

          }).catch(err => {
            console.log(err)
          }) // Creamos nueva relación

          cleanRelationContents(this.selectContent).then(() => {
          }).catch(err => {
            console.log(err)
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('contentsAddToCat')} ${this.categoryName}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.selectContent = []
          this.infoContent = []
          this.$emit('choices', this.selectContent)
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('ErrorContentsAddToCat'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteContent(id = null) {
      const { headers } = this

      if (id != null) {
        this.selectContent = []
        this.infoContent = []
        this.selectContent.push(id)
      }
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.overlay = true
          let query = '{'
          this.selectContent.forEach((element, i) => {
            query += `cont_${i
              + 1}: allContents(id:"${element}") {
                edges {
                  node {
                    id
                    name
                    contentResources {
                      totalCount
                      edges {
                        node {
                          id              
                          resource {
                            id
                            name
                            file {
                              id
                              name
                            }
                            resourceContent {
                              totalCount
                              edges {
                                node {
                                  id
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }      
                  }
                }
              }`
          })
          query += '}'
          axios
            .post('', {
              query,
            }, { headers }).then(r => {
              messageError(r, this)

              const contentResources = r.data.data

              removeResources(contentResources).then(resultado => {
                if (resultado) {
                  this.$swal({
                    title: this.$t('code.title'),
                    text: this.$t('code.resourceOrphan', { resourceName: resultado.name }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('code.confirm'),
                    cancelButtonText: this.$t('dataGeneric.cancel'),
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                  }).then(response => {
                    if (response.value) {
                      let query2 = 'mutation{'
                      resultado.id.forEach((element, i) => {
                        query2 += `
                                resource_${i + 1}: deleteResource(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                      })
                      if (resultado.file.length) {
                        resultado.file.forEach((element, i) => {
                          query2 += `
                                file_${i + 1}: deleteFile(id:"${element}") {
                                    found
                                    deletedId
                                  }`
                        })
                      }
                      query2 += '}'
                      axios.post('', { query: query2 }).then(res => {
                        messageError(res, this)

                        this.delete()
                      }).catch(err => {
                        console.log(err)
                      })
                    } else {
                      this.delete()
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                } else {
                  this.delete()
                }
              }).catch(err => {
                console.log(err)
              })
            }).catch(err => {
              console.log(err)
            })
        }
      })
    },
    delete() {
      let query = 'mutation{'
      this.selectContent.forEach((element, i) => {
        query += `a_${i
          + 1}: updateContents(id:"${element}",input:{resources:[]})
                        {
                          content {
                            id
                            resources {
                              edges {
                                node {
                                  id
                                }
                              }
                            }
                          }
                        }
                b_${i + 1}: deleteContents(id:"${element}") {
                    found
                    deletedId
                  }`
      })
      query += '}'
      axios
        .post('', {
          query,
        })
        .then(r => {
          messageError(r, this)

          this.selectContent.forEach(element => {
            // eslint-disable-next-line vue/no-mutating-props
            this.contents = this.contents.filter(
              item => item.node.id !== element,
            )
          })
          if (this.contents.length === 0) {
            this.$emit('vacio', true)
          } else {
            this.$emit('reload', this.code)
          }
          this.selectContent = []
          this.infoContent = []
          this.$emit('choices', this.selectContent)
          showToast(this.$t('success'), 1, this)

          this.overlay = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.overlay = false
        })
    },

    showModal(content, boleano) {
      if (content != null) {
        this.selectContent.push(content.id)
        this.infoContent.push(content)
      }

      this.$refs['my-modal'].show()
      this.addCat = boleano
    },
    confirm(data) {
      if (this.addCat) { this.addToCategory(data) } else this.moveToCategory(data)
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    changeTypeOrder() {
      axios.post('', {
        query: `
          mutation{
          updateCategory(id:"${this.code}",input:{orderType:MAN})  {
            category {
              id
            }
          }
          }
         `,
      }).then(result => {
        messageError(result, this)
      }).catch(err => {
        console.log(err)
      })
    },
    updateOrder(event) {
      this.changeTypeOrder()
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < event.target.parentNode.childNodes.length; i += 1) {
        arrayId.push(event.target.parentNode.childNodes[i].id.split(' ')[0])
        arrayNum.push(event.target.parentNode.childNodes[i].id.split(' ')[1])
      }

      const result = ['0']

      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = '{'
      for (let id = 0; id < arrayId.length; id += 1) {
        const code = utf8ToB64(`id:${this.code}`)
        const cont = utf8ToB64(`id:${arrayId[id]}`)
        query += `
        m${id + 1}:  allContentOrder(category:"${code}",content:"${cont}") {
          totalCount
            edges {
              node {
                id
                content {
                  id
                }
                category {
                  id
                }
                order
              }
            }
          }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const editar = []

          for (
            let index = 0;
            index < Object.keys(res.data.data).length;
            index += 1
          ) {
            if (res.data.data[`m${index + 1}`].edges.length !== 0) {
              const idRe = res.data.data[`m${index + 1}`].edges[0].node.id
              const ord = res.data.data[`m${index + 1}`].edges[0].node.order
              const conteOrd = res.data.data[`m${index + 1}`].edges[0].node.content.id

              for (let e = 0; e < arrayId.length; e += 1) {
                if (conteOrd === arrayId[e]) {
                  if (ord === result[e]) {
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  } else {
                    editar.push({ id: idRe, order: result[e] })
                    result.splice(e, 1)
                    arrayId.splice(e, 1)
                  }
                }
              }
            }
          }

          if (editar.length !== 0) {
            let query2 = 'mutation{'
            for (let a = 0; a < editar.length; a += 1) {
              query2 += `
          m${a + 1}: updateContentOrder(id:"${editar[a].id}",input:{order:${editar[a].order
}}){
              contentOrder{
                id
              }
            }`
            }
            query2 += '}'
            axios
              .post('', {
                query: query2,
              })
              .then(ress => {
                messageError(ress, this)

                showToast(this.$t('success'), 1, this)
              })
              .catch(() => {
                showToast(this.$t('error'), 2, this)
              })
          }

          // Si no existe se crea la relación
          if (arrayId.length !== 0) {
            const code = utf8ToB64(`id:${this.code}`)
            let query2 = ` mutation
            {`
            for (let i = 0; i < result.length; i += 1) {
              const cont = utf8ToB64(`id:${arrayId[i]}`)
              query2 += `
              m${i + 1}: createContentOrder(input:{ order: ${result[i]
},content:"${cont}",category:"${code}"}){
                          contentOrder{
                            id
                           content {
                              id
                            }
                            category {
                              id
                            }
                            order
                          }
                        }
                      `
            }
            query2 += `
            }`
            axios
              .post('', {
                query: query2,
              })
              .then(r => {
                messageError(r, this)

                showToast(this.$t('success'), 1, this)
              })
              .catch(err => {
                showToast(this.$t('error'), 2, this)
                console.log(err)
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    errorImg(e) {
      e.target.src = fileError
      // "https://cdn.iconscout.com/icon/free/png-256/file-error-1470245-1244839.png";
    },
    released(data) {
      const inicial = data.publishDate == null ? null : new Date(data.publishDate)
      const final = data.expirationDate == null ? null : new Date(data.expirationDate)
      const dateToCheck = new Date()

      if (inicial == null || inicial < dateToCheck) {
        if (final == null || final > dateToCheck) {
          return true
        }
        return false
      }
      return false
    },
  },
}
</script>

<style scoped>
#contents .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#contents .cardContent img {
  height: 11vw;
  object-fit: cover;

}

#contents .card-body {
  padding: 1rem;
}

#contents .card-body h4 {
  font-size: 1rem !important;
}

#contents .card-header {
  padding: 1rem;
}

#contents .card-header h4 {
  font-size: 1rem !important;
}

#contents .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  /* justify-content: flex-end; */
  justify-content: space-between;
}

/*.folder {
  min-height: 150px;
  height: 300px;
  cursor: all-scroll;
}*/
#contents .dropContainer {
  border: 3px dashed;
}

/*.card-width {
  width: 200px;
}*/
#contents .list-group-item {
  transition: all 1s;
}

#contents #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#contents #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
</style>
